import { nMonthsAgo } from "./utils/date";
import { TimePeriodCode } from "./utils/type";

const { NODE_ENV } = process.env;

export const isDevelopment = NODE_ENV === "development";
export const API_URL = window.location.origin;

export const config = {
  general: {
    useMWR: false,
    useProfitAndLoss: false,
  },
  overviewPage: {
    showAdvisor: false,
    defaultTimePeriodCode: "CALYEAR-0" as TimePeriodCode,
    timePeriodCodes: [
      "MONTHS-1",
      "MONTHS-3",
      "MONTHS-6",
      "CALYEAR-0",
      "YEARS-1",
      "YEARS-3",
      "GIVEN",
    ] as TimePeriodCode[],
  },
  portfolioPage: {
    showKID: false,
    defaultTimePeriodCode: "CALYEAR-0" as TimePeriodCode,
    timePeriodCodes: [
      "MONTHS-1",
      "MONTHS-3",
      "MONTHS-6",
      "CALYEAR-0",
      "YEARS-1",
      "YEARS-3",
      "GIVEN",
    ] as TimePeriodCode[],
    portfolioList: {
      timePeriodCodes: [
        "MONTHS-1",
        "MONTHS-3",
        "MONTHS-6",
        "CALYEAR-0",
        "GIVEN",
      ] as TimePeriodCode[],
    },
  },
  costsPage: {
    showCostsPage: true,
    timePeriods: [
      { code: "MONTHS-1" as TimePeriodCode, startDate: nMonthsAgo(1) },
      { code: "MONTHS-3" as TimePeriodCode, startDate: nMonthsAgo(3) },
      { code: "MONTHS-6" as TimePeriodCode, startDate: nMonthsAgo(6) },
      {
        code: "CALYEAR-0" as TimePeriodCode,
        startDate: new Date(new Date().getFullYear(), 0, 1),
      },
    ],
  },
  components: {
    securityDetails: {
      defaultTimePeriodCode: "CALYEAR-0" as TimePeriodCode,
      timePeriodCodes: [
        "MONTHS-1",
        "MONTHS-3",
        "CALYEAR-0",
        "YEARS-1",
        "YEARS-3",
      ] as TimePeriodCode[],
    },
  },
  tradingPage: {
    showTradingPage: false,
  },
  savingsPage: {
    showSavingsPage: false,
  },
};
